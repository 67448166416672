import { ICON_DETAILS, ICON_EDIT } from "@smallstack/typesystem";

/**
 * Used as mapping between icons8 icons and our icons. Will be used as IconAliases in IconThemeService->IconComponent so that "project" is the same icon on every page
 */
export const BackofficeIcons = {
  add: "fa-plus",
  addimage: "add-image",
  administration: "project",
  aligncellcontentbottom: "align-cell-content-bottom",
  aligncellcontentcenter: "align-cell-content-center",
  aligncellcontenttop: "align-cell-content-top",
  aligncenter: "align-center",
  alignleft: "align-left",
  alignright: "align-right",
  aperture: "aperture",
  application: "application-window",
  applications: "application-window",
  appsymbol: "app-symbol",
  arrow: "arrow",
  audit: "fa-eye",
  brush: "paint-brush",
  calendar: "fa-calendar-days",
  camera: "fa-camera",
  cancel: "cancel",
  chatsettings: "chat-settings",
  checkin: "ticket",
  checkmark: "checkmark--v1",
  checked: "fa-square-check",
  clear: "clear-symbol",
  close: "delete-sign",
  closepane: "close-pane",
  cms: "edit-property",
  cmsedit: "edit-column",
  company: "fa-building",
  configuration: "administrative-tools",
  contact: "contact-card",
  copy: "copy",
  custom: "plugin",
  custombackoffices: "switchboard",
  customdata: "opened-folder",
  customers: "user-group-woman-woman",
  dashboard: "dashboard",
  datapending: "data-pending",
  delete: "fa-trash-can",
  device: "touchscreen-smartphone",
  devices: "multiple-devices",
  details: ICON_DETAILS,
  edit: ICON_EDIT,
  email: "fa-envelope",
  emailtemplates: "email-document",
  employee: "employee",
  engineering: "engineering",
  error: "fa-square-exclamation",
  event: "planner",
  execute: "fa-play",
  expandarrow: "expand-arrow--v1",
  export: "fa-file-export",
  files: "opened-folder",
  filter: "fa-filter",
  form: "fa-square-list",
  grid: "fa-table-cells",
  help: "fa-square-question",
  hide: "hide",
  home: "fa-home",
  i18n: "fa-earth-europe",
  imagefile: "image-file",
  impex: "open-box",
  import: "database-import",
  info: "fa-circle-info",
  invitations: "invite",
  invoice: "invoice",
  janitor: "housekeeper-male",
  key: "key",
  language: "fa-earth-europe",
  launch: "rocket",
  lessthan: "less-than",
  list: "list",
  lock: "lock",
  menu: "fa-bars",
  messages: "messages",
  messenger: "fa-messages",
  minus: "fa-minus",
  morethan: "more-than",
  move: "move-grabber",
  multipledevices: "multiple-devices",
  name: "name",
  navigation: "left-navigation-toolbar",
  nomessage: "no-message",
  notification: "appointment-reminders--v1",
  okay: "ok--v1",
  openinbrowser: "fa-square-arrow-up-right",
  openpane: "open-pane",
  optin: "checked-checkbox",
  orders: "paid",
  pagelist: "overview-pages-1",
  pdf: "fa-file-pdf",
  people: "conference-call",
  pin: "fa-location-pin",
  point: "full-stop",
  pricezone: "price-tag",
  printer: "print",
  printerconfig: "printer-maintenance",
  products: "new-product",
  profile: "fa-user",
  project: "fa-diagram-project",
  qrcode: "qr-code",
  refresh: "refresh--v1",
  remove: "minus",
  roles: "commercial-development-management",
  save: "fa-floppy-disk",
  search: "fa-magnifying-glass",
  sendemail: "send-mass-email",
  sentemail: "outbox",
  settings: "settings",
  sort: "sort",
  subscriptions: "doughnut-chart--v1",
  suppliers: "shipped",
  taskqueue: "todo-list",
  template: "static-views",
  threedotmenu: "menu-2",
  transactionlist: "transaction-list",
  translation: "translation",
  tvshow: "tv-show",
  unlock: "unlock",
  upload: "upload--v1",
  user: "fa-user",
  users: "fa-users",
  visible: "visible",
  view: "visible",
  visitors: "queue",
  warning: "box-important--v1",
  addWidget: "fa-kit fa-regular-cube-circle-plus",
  widget: "fa-cube",
  workflow: "workflow"
};
